// @ts-check
import React, { useContext, useEffect, useReducer } from 'react';

// Core.
import { AuthContext, BubbleLoader, RequiredIcon } from 'core';
import { useAppDispatch, useAppSelector } from 'core/store';
import { updateAnnouncementAsync } from 'core/store/slices';

// Local components.
import { AnnouncementContent } from './components/AnnouncementContent';
import { AnnouncementDate } from './components/AnnouncementDate';
import { AnnouncementHistory } from './components/AnnouncementHistory';
import { AnnouncementTitle } from './components/AnnouncementTitle';

/**
 * @param {Partial<AnnouncementModel>} state
 * @param {Partial<AnnouncementModel>} payload
 */
const handleFormReducer = (state, payload) => ({
  ...state,
  ...payload,
});

const DataForm = () => {
  const { user } = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const { announcement, loading: announcementLoading } = useAppSelector(
    (state) => state.announcementData,
  );

  const initialFormData = {
    content: announcement.content,
    title: announcement.title,
    endAt: announcement.endAt,
    startAt: announcement.startAt,
    lastUpdatedBy: announcement.lastUpdatedBy,
    updatedAt: announcement.updatedAt,
  };

  const saveAnnouncement = async (e) => {
    e.preventDefault();

    console.log('saveAnnouncement', formData);

    const saveActionAsync = announcement?.announcementId
      ? updateAnnouncementAsync
      : updateAnnouncementAsync; //TODO: Add createAnnouncementAsync.

    dispatch(
      saveActionAsync({ announcement: { ...formData, lastUpdatedBy: user } }),
    );
  };

  useEffect(() => {
    setFormData({
      ...announcement,
    });
  }, [announcement]);

  const [formData, setFormData] = useReducer(handleFormReducer, {
    content: announcement.content,
    title: announcement.title,
    endAt: announcement.endAt,
    startAt: announcement.startAt,
    lastUpdatedBy: announcement.lastUpdatedBy,
    updatedAt: announcement.updatedAt,
  });

  const htmlChange = (e) => {
    setFormData({ content: e.target.value });
  };

  const startDateChange = (date) => {
    setFormData({ startAt: date });
  };

  const endDateChange = (date) => {
    setFormData({ endAt: date });
  };

  const inputChange = (e) => {
    const { name, value } = e.target;

    /** @type {any} */
    setFormData({ [name]: value });
  };

  const resetForm = () => {
    setFormData(initialFormData);
  };

  const classFirstCol = 'col-md-4';

  return (
    <form className="uds-form" noValidate method="post">
      <div className="container p-0">
        {announcementLoading ? (
          <BubbleLoader />
        ) : (
          <>
            <header className={`${classFirstCol} mb-3`}>
              <label>
                <RequiredIcon />
                <span className="font-weight-normal">Required fields</span>
              </label>
            </header>
            <section className={classFirstCol}>
              <AnnouncementTitle
                announcementTitle={formData.title}
                errors={[]}
                readonly={false}
                onChange={inputChange}
              />
            </section>
            <section className={classFirstCol}>
              <AnnouncementContent
                announcementContent={formData.content}
                errors={[]}
                readonly={false}
                onChange={htmlChange}
              />
            </section>
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <AnnouncementDate
                      dateId="startAt"
                      dateName="startAt"
                      announcementDate={formData.startAt}
                      dateTitle="Start Date"
                      tooltip="The start date of the announcement, potentially public; minimum 5 characters."
                      errors={[]}
                      readOnly={false}
                      onChange={startDateChange}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <AnnouncementDate
                      dateId="endAt"
                      dateName="endAt"
                      announcementDate={formData.endAt}
                      dateTitle="End Date"
                      tooltip="The end date of the announcement, potentially public; minimum 5 characters."
                      errors={[]}
                      readOnly={false}
                      onChange={endDateChange}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <button
                      className="btn btn-gray"
                      type="button"
                      aria-describedby="cancel-announcemnt"
                      onClick={resetForm}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-maroon ms-3"
                      type="button"
                      aria-describedby="save-announcement-tooltip"
                      onClick={saveAnnouncement}
                    >
                      Save Announcement
                    </button>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <AnnouncementHistory
                      createdBy={announcement.createdBy}
                      createdAt={announcement.createdAt}
                      lastUpdatedBy={announcement.lastUpdatedBy}
                      updatedAt={announcement.updatedAt}
                    />
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </form>
  );
};

export { DataForm };
