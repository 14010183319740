// @ts-check

import React, { useContext, useState } from 'react';
import styled from 'styled-components';

// core
import { AuthContext } from 'core/auth';
import { useAppSelector } from 'core/store';

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-transform: capitalize;
  }

  .summary-left {
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: -0.07px;
    color: #2a2a2a;
    opacity: 1;
  }

  .btn-icon {
    all: unset;
    cursor: pointer;
  }

  .hide-icon {
    display: none;
  }

  button:not([aria-checked='true']) {
    .fa-caret-down {
      display: none;
    }
  }
  button[aria-checked='true']
    .fa-caret-up {
      display: none;
    }
  }
`;

const NavSummary = () => {
  const { user, ssoName, userRoles } = useContext(AuthContext);
  const { pageSummary } = useAppSelector((state) => state.shared.feedback);
  const [showRoles, setShowRoles] = useState(true);
  const userName = ssoName || user?.replace('@asu.edu', '');
  return (
    <Container>
      <section className="summary-left">{pageSummary}</section>
      <section>
        {userName && (
          <div>
            <strong className="me-1">{userName}</strong>
            <button
              className="btn-icon"
              type="button"
              role="switch"
              aria-checked={showRoles}
              onClick={() => setShowRoles(!showRoles)}
            >
              <i className="fa-solid fa-caret-down" />
              <i className="fa-solid fa-caret-up" />
            </button>
          </div>
        )}

        {showRoles && userRoles?.length > 0 && (
          <ul>
            {userRoles?.map((role) => (
              <li key={role}>{role}</li>
            ))}
          </ul>
        )}
      </section>
    </Container>
  );
};

export { NavSummary };
