// @ts-check
import React from 'react';
import styled from 'styled-components';
import { ASUFooter } from '@asu/component-header-footer';

const FooterSection = styled.div`
  footer {
    .footer-innovation-links {
      a {
        order: 2;
      }

      nav {
        order: 1;
      }
    }
`;
function Footer() {
  return (
    <FooterSection className="app-footer">
      <ASUFooter />
    </FooterSection>
  );
}

export { Footer };
