// @ts-check

import { formatDate } from 'core/utils';

/** @type {TableColumn[]} */
const columns = [
  {
    id: 'name',
    text: 'Campaign Name',
    width: '250px',
    cellStyle: {
      fontWeight: 'bold',
    },
  },
  {
    id: 'created_at',
    text: 'Date Created',
    format: (value) => formatDate(value),
  },
  {
    id: 'created_by',
    text: 'Created By',
  },
  {
    id: 'published',
    text: 'Status',
    dataType: 'boolean',
    componentType: 'badge',
    format: (value) => (value ? 'Published' : 'Saved'),
    computedValue: (value) => (value ? 'text-bg-success' : 'text-bg-info'),
  },
  {
    id: 'view_details',
    text: '',
    componentType: 'button',
    componentProps: {
      text: 'View Details',
    },
    textAlign: 'center',
  },
];

/** @type {TableConfig} */
const campaignTableConfig = {
  columns,
  clientSorting: true,
  rowKeyId: 'campaign_id',
};

export { campaignTableConfig };
