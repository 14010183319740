// @ts-check

import { fetchData } from 'core/utils/http-utils';

/**
 * @param {string} statementId
 */
async function getStatement(statementId) {
  const url = `/statement/${statementId}`;

  /** @type {AxiosResponse<Statement>} */
  const response = await fetchData(url);

  return response.data;
}

export { getStatement };
