// @ts-check

import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// core
import {
  AppContext,
  AuthContext,
  createCampaign,
  formatQueryCampaignMessage,
  OPEN_MODE,
  PAGE_STEP,
  resolveRouteWithId,
  routerUrl,
  scrollToElement,
  scrollTopPage,
  useScrollTop,
} from 'core';
import {
  Breadcrumb,
  CsvDataTable,
  ElementTooltip,
  PageTitle,
  processQuery,
  QueryFilterBuilder,
  QueryFilterPreview,
} from 'core/components';
import { previewTableConfig } from 'core/components/Tables/configs';
import {
  useStateCampaign,
  useStateFilters,
} from 'core/hooks/use-state-campaign';

// state
import { useAppDispatch, useAppSelector } from 'core/store';
import {
  createCampaignAsync,
  debounceStoreDraft,
  getDisplayContentAsync,
  setCampaignField,
  setCampaignPreviewLoading,
  setCampaignPreviewWebsocket,
  setErrorMessage,
  setPageSummary,
  setSelectedRoute,
  updateCampaignAsync,
} from 'core/store/slices';

// components
import { DisplayContent } from './components/DisplayContent';

const HeaderSection = styled.header`
  display: flex;
  align-items: center;
`

const DictionaryHeader = styled.div`
  font-weight: bold;
  margin-left: 20px;
`;

const Icon = styled.i`
 margin-right: 10px;
`;

const CampaignDefineFilter = () => {
  const { user } = useContext(AuthContext);
  const { sendMessage, stopSendMessage } = useContext(AppContext);

  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state);

  const { campaign, openMode } = state.campaignData;
  const readonly = openMode === OPEN_MODE.VIEW;

  const { loading: previewLoading, previewData } = state.campaignPreview;
  const { filters } = state.queryBuilder;

  const { shared } = state;
  const {
    feedback: { errorMessage },
  } = shared;

  useStateFilters();
  useStateCampaign();
  useScrollTop();

  /** @type {HistoryState} */
  const history = useHistory();

  /** @type {[QueryFilterValue, (value: QueryFilterValue) => void]} */
  const [queryOutput, setQueryOutput] = useState();
  const [hasValidRuleValues, setValidRuleValues] = useState(true);
  const [showQueryError, setShowQueryError] = useState(false);

  useEffect(() => {
    setQueryOutput(campaign.logic ? processQuery(campaign.logic) : undefined);
  }, [campaign.logic]);

  useEffect(() => {
    dispatch(setSelectedRoute(routerUrl.CAMPAIGN_DEFINE_FILTER));
    dispatch(setPageSummary('Step 2 of 2'));
    //TODO: Fix getDisplayContentAsync() call. It is expecting 1 parameter but currently passing none.
    // @ts-ignore
    dispatch(getDisplayContentAsync());
  }, [dispatch]);

  // Scroll user's browswer window to the top of page if there is an error message.
  useEffect(() => {
    if (!!errorMessage) {
      scrollTopPage();
    }
  }, [errorMessage]);

  const hasValidRules = () => {
    const len = queryOutput?.jsonQuery?.rules?.length;
    return len > 0 && hasValidRuleValues;
  };

  const saveCampaign = async (e) => {
    e.preventDefault();

    if (!hasValidRules()) {
      setShowQueryError(true);
      scrollToElement('#query-builder');
      return;
    }

    const newCampaign = /** @type {CampaignModel} */ ({
      ...campaign,
      createdByName: user,
      logic: queryOutput.jsonQuery,
    });

    const saveActionAsync = campaign?.campaignId
      ? updateCampaignAsync
      : createCampaignAsync;

    dispatch(
      saveActionAsync({
        campaign: newCampaign,
        reloadData: true,
        infoMessage: `Campaign has been ${
          campaign?.campaignId ? 'updated' : 'saved'
        }`,
      }),
    )
      .unwrap()
      .then((newCampaignId) => {
        newCampaignId &&
          history.push(
            resolveRouteWithId(routerUrl.CAMPAIGN_DETAILS, newCampaignId),
          );
      });
  };

  const viewCampaign = () => {
    history.push(
      resolveRouteWithId(routerUrl.CAMPAIGN_DETAILS, campaign?.campaignId),
    );
  };

  const { displayContent, loading } = useAppSelector(
    (state) => state.displayContent,
  );

  /** @param {QueryFilterValue} output */
  const queryChange = (output) => {
    setQueryOutput(processQuery(output.jsonQuery));
    debounceStoreDraft(dispatch, { logic: output.jsonQuery });
  };

  const queryClean = () => {
    dispatch(
      setCampaignField({
        logic: null,
      }),
    );
  };

  const queryValidate = (isValidQuery) => {
    setValidRuleValues(isValidQuery);
  };

  const cancelApplyFilter = () => {
    dispatch(setCampaignPreviewLoading(false));
    stopSendMessage();
  };

  const applyFilter = async () => {
    dispatch(setCampaignPreviewLoading(true));
    // =============================================
    // - Save campaign to get a new campaign ID.
    // The campaign object is read-only. Thus, if necessary, use
    // currentCampaignId to intermediately store newly created ID.
    /* TODO: consider to create the campaign when the user
    clicks `next` and then remove this code */
    // =============================================
    let currentCampaignId = campaign?.campaignId;

    if (!campaign?.campaignId) {
      const { campaign_id: campaignId } = await createCampaign({
        ...campaign,
        logic: queryOutput.jsonQuery,
      });

      // Intermediately store the newly created campaignId that is returned from campaignDataSlice.
      currentCampaignId = campaignId;

      if (campaignId) {
        dispatch(
          setCampaignField({
            campaignId,
          }),
        );
      } else {
        dispatch(setCampaignPreviewLoading(false));
        dispatch(
          setErrorMessage(
            'At them moment is not possible to generate the filter preview.' +
              `Please, try again later.`,
          ),
        );
        console.error('ERROR: createCampaign() failed');
        return null;
      }
    }

    // =============================================
    // - Check if filter object reference has changed
    // =============================================
    if (queryOutput.jsonQuery === previewData.queryLogic) {
      setTimeout(() => dispatch(setCampaignPreviewLoading(false)), 100);
      return;
    }

    // =============================================
    // - Send message to websocket.
    // Set campaignId to the newly created (or current) campaignId.
    // =============================================
    const newCampaign = {
      ...campaign,
      logic: queryOutput.jsonQuery,
      campaignId: currentCampaignId,
    };
    const message = formatQueryCampaignMessage(newCampaign);

    sendMessage({
      handleError: true,
      message,
      onMessageCallback: (evt) => {
        dispatch(
          setCampaignPreviewWebsocket({
            wsData: evt.data,
            queryLogic: newCampaign?.logic,
          }),
        );
      },
    });
  };

  return (
    <section>
      <header>
        <HeaderSection>
          <div>
            <PageTitle title="Define filters" />
          </div>
          <DictionaryHeader>
            <span>
            <Icon className="fa-solid fa-up-right-from-square text-maroon" />
            </span>
            <span>
            <a
              href="https://docs.google.com/spreadsheets/d/1pdhpt_jn9wdIEVxcmOUJTb133QJBIYl0agPEqNxesHM/edit#gid=312242724"
              target="_blank"
              rel="noreferrer"
            >
              Filters Dictionary
            </a></span>
          </DictionaryHeader>
        </HeaderSection>
        <Breadcrumb
          currentPage={PAGE_STEP.CAMPAIGN_FILTER}
          openMode={openMode}
          onRouteChange={() => {
            dispatch(
              setCampaignField({
                logic: queryOutput.jsonQuery,
              }),
            );
          }}
        />
        <DisplayContent
          loading={loading}
          censusDate={displayContent.censusDate}
          currentTerm={displayContent.currentTerm}
          lastRefreshDate={displayContent.lastRefreshDate}
          priorTerm={displayContent.priorTerm}
          priorRegularTerm={displayContent.priorRegularTerm}
          nextRegularTerm={displayContent.nextRegularTerm}
          nextTerm={displayContent.nextTerm}
        />
        <h3>
          <span>{campaign?.campaignName}</span>
        </h3>
      </header>
      <section>
        <header>
          <h4>Population Filters</h4>
        </header>
        <p>
          Define the campaign's population of students with the filters below.
          The overall population of students the data are pulled from is defined
          as all living, plan stack active students.
        </p>
        <QueryFilterBuilder
          id="query-builder"
          checkErrors={showQueryError}
          readOnly={readonly}
          valueQuery={queryOutput?.valueQuery}
          filters={filters}
          maxRows={20}
          applyFilterInProgress={previewLoading}
          onApplyFilter={applyFilter}
          onCancelApplyFilter={cancelApplyFilter}
          onQueryChange={queryChange}
          onQueryClean={queryClean}
          onQueryValidate={queryValidate}
        />
      </section>
      <section>
        <section className="container mt-4 p-0">
          <header>
            <h4>Campaign Preview</h4>
          </header>
          <article>
            <p>
              To see a preview of the data, please select the filters above.
              <br />
              The "Apply Filters" button will generate a preview of the data.
            </p>
          </article>
        </section>
      </section>

      <section>
        <QueryFilterPreview query={queryOutput} />
      </section>

      <section className="mb-2">
        <CsvDataTable
          loading={previewLoading}
          config={previewTableConfig}
          columns={previewData.columns}
          records={previewData.records}
          totalRecordCount={previewData.totalRecordCount}
          height="300px"
        />
      </section>

      <footer>
        <ElementTooltip
          tooltipId="save-campaign-tooltip"
          tooltip={
            'This allows for the Student List to be saved ' +
            'without Publishing it. ' +
            'It will save the list, logic, and metadata. ' +
            'In a Not Published status, the Campaign can be edited. ' +
            'A Campaign must be saved prior to being Published.'
          }
          direction="right"
        >
          {campaign?.published || readonly ? (
            <button
              type="button"
              className="btn btn-md btn-maroon"
              onClick={viewCampaign}
            >
              View details
            </button>
          ) : (
            <button
              data-testid="bn-save"
              type="button"
              className="btn btn-md btn-maroon"
              aria-describedby="save-campaign-tooltip"
              onClick={saveCampaign}
            >
              {campaign?.campaignId ? 'Update Campaign' : 'Save Campaign'}
            </button>
          )}
        </ElementTooltip>
      </footer>
    </section>
  );
};

export { CampaignDefineFilter };
